
import {defineComponent} from 'vue'

export default defineComponent({
  name: `AdditionalOption`,
  props: {
    packages: {
      type: Array,
      default: () => []
    },
    options: {
      type: [String, Array],
      default: null
    },
    value: {
      type: Object,
      default: () => {}
    },
    currentPackage: {
      type: String,
      default: null
    }
  },
  data: () => ({
    packageOptions: {},
    visiblePackageOptions: {},
  }),
  computed: {},
  watch: {
    currentPackage() {
      this.injectPackageOptions({emit: true})
    }
  },
  mounted() {
    this.injectPackageOptions({})
  },
  methods: {
    toggleOptions(option) {
      if (option.locked) return
      option.activate = !option.activate

      // Check if any excluded options need to be deactivated
      if (option.activate) {
        option.excluded_options.forEach(excludedOptionName => {
          const excludedOption = this.packageOptions[excludedOptionName];
          if (excludedOption && excludedOption.activate) {
            // Deactivate the excluded option if it's currently activated
            excludedOption.activate = false
          }
        });
      }

      this.$emit(`toggle-options`, this.packageOptions)
      this.$forceUpdate()
    },
    injectPackageOptions({emit = false}) {
      const packageObject = this.packages.find((p) => p.name === this.currentPackage)
      this.packageOptions = packageObject?.options || {}
      if (emit) {
        this.$emit(`toggle-options`, this.packageOptions)
      }
      this.visibleOptions();
    },
    visibleOptions() {
      this.visiblePackageOptions = Object.keys(this.packageOptions)
        .filter(optionName => !this.hide(this.packageOptions[optionName]))
        .reduce((acc, optionName) => {
          acc[optionName] = this.packageOptions[optionName];
          return acc;
        }, {});
    },
    hide(option) {
      for (const excludedOptionName of option.excluded_options) {
        const excludedOption = this.packageOptions[excludedOptionName];
        if (excludedOption && excludedOption.activate && excludedOption.locked) {
          return true;
        }
      }
      return false;
    }
  }
})
